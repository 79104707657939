<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
// import { mapState } from 'vuex';
import axios from '@/utils/request.js';

export default {
  name: 'App',
  created() {
    // console.log('NODE_ENV-------'+ process.env.NODE_ENV)

    // console.log(this.$route);

    let taskId = this.$route.query.taskId,
      color = this.$route.query.color;
    this.$store.commit('setTaskId', Number(taskId));
    this.$store.commit('setColor', color);

    let url = window.location.origin;
    if (url.indexOf('h5client') > -1 || url.indexOf('cfpeo') > -1) {
      document.title = '承宝';
      this.$store.commit('setPlatform', 'DAMAI');
    } else {
      document.title = '立派';
      this.$store.commit('setPlatform', 'LIPAI');
    }
    // this.$router.push({
    //   name: 'vehicle',
    //   query: { taskId, color,taskNum:'CA2022072600000001',type:'contract' },
    // });
    // this.$router.push({
    //   name: 'vehicle',
    //   query: { taskId, color },
    // });
    // this.$router.push({
    //   name: 'download',
    //   query: { code: 'UedVde18' },
    // });
    // this.$router.push({
    //   name: 'resign',
    //   query: {
    //     color,
    //     taskId,
    //     no: 'CA2022080500000003',
    //   },
    // });
    // this.$router.push({
    //   name: 'irregular',
    //   query: { entId: 266 },
    // });
    // this.$router.push({
    //   name: 'mt',
    //   query: { coll: true, code: 1234 },
    // });
    // this.$router.push({
    //   name: 'didi',
    //   query: { taskId: 1 },
    // });
    this.getPlatformByUrl();
  },
  methods: {
    async getPlatformByUrl() {
      const data = await this.$axios.post(
        '/system/platformController/getPlatformByUrl',
        {
          pcUrl: window.location.origin.includes('localhost')
            ? 'www.aaa.com'
            : window.location.origin,
        }
      );
      if (data.success) {
        let { id } = data.data;
        axios.defaults.headers.common['platformId'] = id;
        this.$store.commit('setPlatformId', id);
      }
    },
  },
};
</script>
