<template>
  <div class="ClockinResult">
    <van-icon v-if="flag" name="passed" size="80" color="#27ae60" />
    <van-icon v-else name="close" size="80" color="#eb5757" />
    <div class="tips">{{ getSignType(detail.signTimeType) }}</div>
    <p>
      任务类型：{{ detail.signType == 1 ? '任务执行开始' : '任务执行结束' }}
    </p>
    <p>签到时间：{{ $dayjs().format('HH:mm') }}</p>
    <p>签到地点：{{ detail.address }}</p>
    <p>姓名：{{ detail.name }}</p>
    <p>身份证号：{{ detail.idNo }}</p>
  </div>
</template>

<script>
import Vue from 'vue';
import { Icon } from 'vant';

Vue.use(Icon);

export default {
  name: 'ClockinResult',
  data() {
    return {
      flag: false,
      detail: {},
    };
  },
  methods: {
    getSignType(status) {
      // 打卡时间类型 ：signTimeType  0不在执行任务时间范围内 1任务已确认开始 2正常开始任务 3任务延迟确认 4任务提前确认 5正常结束任务 6未排班
      let m = new Map([
        [2, '执行任务已完成'],
        [5, '执行任务已完成'],
        ['default', '执行任务异常'],
      ]);
      return m.get(status) || m.get('default');
    },
    async handleClockin(postData) {
      const data = await this.$axios.post(
        '/tax/wechat/crowdTask/scanSignIn',
        postData
      );
      if (data.success) {
        this.detail = data.data;
        if ([2, 5].includes(data.data.signTimeType)) {
          this.flag = true;
        } else {
          this.flag = false;
        }
      }
      this.isSubmiting = false;
    },
  },
  created() {
    const { openId, platformId, taskId, idNo } = this.$route.query,
      obj = { openId, platformId, taskId, idNo };
    this.handleClockin(obj);
  },
};
</script>

<style lang="scss" scoped>
.ClockinResult {
  min-height: 100vh;
  background: #fff;
  padding-top: 50%;
  box-sizing: border-box;
  text-align: center;

  .tips {
    margin: 0.12rem 0;
    color: #666;
    font-size: 0.24rem;
  }
}
</style>
