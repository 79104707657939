<template>
  <div class="Standard">
    <div class="title">请拍摄并上传您的有效身份证</div>

    <div class="upload-wrapper">
      <div class="item item-front" @click="chooseFileFront">
        <van-uploader ref="cardFront" :after-read="afterRead1">
          <div class="inner">
            <img v-if="!image1" src="../assets/auth_front.png" alt="" />
            <img v-else class="uploaded-img" :src="image1" alt="" />
            <p :style="{ background: '#' + $store.state.color }">
              身份证人像面
            </p>
          </div>
        </van-uploader>
      </div>
      <div class="item item-back" @click="chooseFileBack">
        <van-uploader ref="cardBack" :after-read="afterRead2">
          <div class="inner">
            <img v-if="!image2" src="../assets/auth_back.png" alt="" />
            <img v-else class="uploaded-img" :src="image2" alt="" />
            <p :style="{ background: '#' + $store.state.color }">
              身份证国徽面
            </p>
          </div>
        </van-uploader>
      </div>
    </div>

    <van-divider />

    <div class="upload-tips">
      <p><b>拍摄身份证要求：</b></p>
      <p>大陆公民持有的本人有效二代身份证；</p>
      <p>拍摄时确保身份证<span>边框完整，字体清晰，亮度均匀；</span></p>
      <ul>
        <li>
          <img src="../assets/auth_tips_01.png" alt="" />
          <span>标准</span>
        </li>
        <li>
          <img src="../assets/auth_tips_02.png" alt="" />
          <span>边框缺失</span>
        </li>
        <li>
          <img src="../assets/auth_tips_03.png" alt="" />
          <span>边框模糊</span>
        </li>
        <li>
          <img src="../assets/auth_tips_04.png" alt="" />
          <span>闪光强烈</span>
        </li>
      </ul>
    </div>

    <van-divider class="divider" />

    <div class="result-wrapper">
      <van-field v-model="form.name" label="姓名" placeholder="请输入姓名" />
      <van-field
        v-model="form.idNo"
        label="身份证号"
        placeholder="请输入身份证号"
        readonly
      />
      <van-field
        v-model="form.phone"
        label="手机号码"
        placeholder="请输入手机号码"
      />
      <van-field
        v-model="form.smsCode"
        center
        clearable
        label="短信验证码"
        maxlength="4"
        placeholder="请输入短信验证码"
      >
        <template #button>
          <van-button
            v-if="!counting"
            class="btn-send"
            size="small"
            type="info"
            plain
            :disabled="form.phone.length < 11"
            @click="getCode"
            >获取验证码</van-button
          >
          <span v-if="counting" class="count-down">{{ seconds }}s后可重发</span>
        </template>
      </van-field>
      <div class="tips">
        <span @click="showAgreement('privacy')">《平台隐私政策协议》</span>
        <span @click="showAgreement('register')">《平台注册服务协议》</span>
      </div>
      <button
        v-if="!isSubmiting"
        class="btn"
        :disabled="isDisabled"
        :style="{ background: '#' + $store.state.color }"
        @click="handleSubmit"
      >
        身份认证
      </button>
      <button v-else class="btn" disabled>提交中</button>
    </div>

    <van-dialog
      v-model="agreementVisible"
      confirmButtonText="我已阅读并同意以上条款"
      width="3.6rem"
      @confirm="agreementVisible = false"
    >
      <Agreement :type="type" :agreementVisible="agreementVisible" />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  ActionSheet,
  Button,
  Dialog,
  Divider,
  Field,
  Notify,
  Toast,
  Uploader,
} from 'vant';
import Agreement from '../components/Agreement.vue';
import OSS from 'ali-oss';
import { getOssToken, getOssFile } from '../utils/getOss';
import Compressor from 'compressorjs';

Vue.use(ActionSheet)
  .use(Button)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Notify)
  .use(Uploader);

export default {
  name: 'Standard',
  components: {
    Agreement,
  },
  data() {
    return {
      image1: null,
      image2: null,
      successFlag1: false,
      successFlag2: false,
      form: {
        idCardFront: '',
        idCardReverse: '',
        name: '',
        idNo: '',
        phone: '',
        smsCode: '',
        smsCodeId: '',
        issuingAuthority: '',
        issuingDate: '',
        loseDate: '',
      },
      seconds: 60,
      counting: false,
      uploading: null,
      agreementVisible: true,
      // baseUrl: '',
      isSubmiting: false,
      clientOSS: {},
      randomString: '',
      type: 'privacy',
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.idNo ||
        !this.form.phone ||
        !this.form.smsCode ||
        !this.image1 ||
        !this.image2 ||
        !this.successFlag1 ||
        !this.successFlag2
      );
    },
    ...mapState({
      taskId: (state) => state.taskId,
      color: (state) => state.color,
    }),
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  methods: {
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    chooseFileFront() {
      this.$refs.cardFront.chooseFile();
    },
    chooseFileBack() {
      this.$refs.cardBack.chooseFile();
    },
    async afterRead1(file) {
      let image = file.file;
      // console.log('before', image.size);
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
        // console.log('after', image.size);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `li_pai/cert/${date}/${t}-${this.randomString}-01${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        console.log(res);
        this.image1 = url;
        this.form.idCardFront = `/${path}`;
        this.handleOcr(url, 'front');
      } else {
        this.uploading.clear();
      }
    },
    async afterRead2(file) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `li_pai/cert/${date}/${t}-${this.randomString}-02${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        this.image2 = url;
        this.form.idCardReverse = `/${path}`;
        // this.handleOcr(url, 'back');
        this.successFlag2 = true;
      } else {
        this.successFlag2 = false;
      }
      this.uploading.clear();
    },
    async handleOcr(url, side) {
      const data = await this.$axios.post('/tool/baidu/urlIdNoOcr', {
        url,
        side,
        flag: 1,
      });
      if (data.success) {
        if (side == 'front') {
          let { 姓名: name, 公民身份号码: idno } = data.data.words_result;
          name && (this.form.name = name.words);
          idno && (this.form.idNo = idno.words);
          this.successFlag1 = true;
        } else {
          let {
            签发机关: issuingAuthority,
            签发日期: issuingDate,
            失效日期: loseDate,
          } = data.data.words_result;
          // if (!issuingAuthority || !issuingDate || !loseDate) {
          //   this.successFlag2 = false;
          //   Toast({
          //     message: '请上传正确的身份证图片',
          //     position: 'bottom',
          //   });
          //   return;
          // }
          issuingAuthority &&
            (this.form.issuingAuthority = issuingAuthority.words);
          issuingDate && (this.form.issuingDate = issuingDate.words);
          loseDate &&
            (this.form.loseDate =
              loseDate == '长期--' ? '9999-12-30' : loseDate.words);
          this.successFlag2 = true;
        }
      } else {
        side == 'front'
          ? (this.successFlag1 = false)
          : (this.successFlag2 = false);
      }
      this.uploading.clear();
    },
    async getCode() {
      let reg = /^1\d{10}$/;
      if (!reg.test(this.form.phone)) {
        Toast({
          message: '请输入正确格式的手机号码',
          position: 'bottom',
        });
        return;
      }
      this.counting = true;
      this.seconds = 60;
      let t1 = setInterval(() => {
        if (this.seconds < 1) {
          this.counting = false;
          clearInterval(t1);
        } else {
          this.seconds--;
        }
      }, 1000);
      const data = await this.$axios.post('/system/wechatController/sendMsg', {
        positionId: this.taskId,
        phone: this.form.phone,
        type: 3,
      });
      if (data.success) {
        this.form.smsCodeId = data.data;
      }
    },
    async handleSubmit() {
      this.isSubmiting = true;
      let postData = { ...this.form, positionId: this.taskId };
      const data = await this.$axios.post(
        '/tax/wechat/user/addUserInfo',
        postData
      );
      if (data.success) {
        this.getUrl(data.data);
        this.uploading = Toast.loading({
          message: '合同生成中',
          forbidClick: true,
        });
      } else {
        this.isSubmiting = false;
      }
    },
    async getUrl(accountId) {
      const data = await this.$axios.post('/tax/wechatController/doEleSign', {
        accountId,
        taskId: this.taskId,
      });
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.uploading.clear();
          this.$router.replace('result');
        }
      }
      this.isSubmiting = false;
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    showAgreement(type) {
      this.type = type;
      this.agreementVisible = true;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.Standard {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.58rem;
  box-sizing: border-box;

  .title {
    padding: 0.1rem 0 0.16rem;
    color: #999;
    font-size: 0.13rem;
    text-align: center;
  }

  .upload-wrapper {
    padding: 0 0.2rem;
    text-align: center;

    .item {
      position: relative;
      z-index: 10;
      display: inline-block;
      width: 1.8rem;
      height: 1.62rem;
      background: #f4f8fe;
      border-radius: 0.1rem;
      vertical-align: top;

      .van-uploader {
        z-index: -10;
        pointer-events: none;
      }

      &.item-front {
        margin-right: 0.07rem;
      }

      &.item-back {
        margin-left: 0.07rem;
      }

      .inner {
        img {
          display: inline-block;
          width: 1.5rem;
          height: 1rem;
          margin: 0.15rem;
          vertical-align: top;
        }

        p {
          height: 0.32rem;
          line-height: 0.32rem;
          background: $blue;
          border-radius: 0 0 0.1rem 0.1rem;
          color: #fff;
          text-align: center;

          @at-root .theme-orange & {
            background: $orange;
          }
        }
      }
    }
  }

  .upload-tips {
    margin: 0.2rem;

    p {
      b {
        display: block;
        margin-bottom: 0.1rem;
      }

      span {
        color: #ff5050;
      }
    }

    ul {
      display: flex;
      margin-top: 0.24rem;

      li {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
          padding-right: 0.05rem;
        }

        img {
          width: 100%;
        }

        span {
          color: #919191;
          font-size: 0.12rem;
        }
      }
    }
  }

  .divider {
    margin: 0;
  }

  .result-wrapper {
    .btn-send {
      @at-root .theme-orange & {
        color: $orange;
        border-color: $orange;
      }

      &:disabled {
        color: #e1e4ea;
        border-color: #e1e4ea;
        opacity: 1;
      }
    }

    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $blue;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      border-radius: 0.22rem;
      color: #fff;
      font-size: 0.17rem;
      // box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);
      box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

      @at-root .theme-orange & {
        background: $orange;
        border-color: $orange;
        // box-shadow: 0 0.03rem 0.1rem rgba($orange, 0.3);
      }

      &:disabled {
        background-color: $gray !important;
        // box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }

  .tips {
    margin: 0.2rem 0;
    text-align: center;

    span {
      color: $blue;

      @at-root .theme-orange & {
        color: $orange;
      }
    }
  }
}
</style>
