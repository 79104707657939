import request from '@/utils/request.js';

export const getOssToken = () => {
  return request({
    url: '/tool/oss/getOssToken',
    method: 'post',
  });
};

// export const getOssFile = async (clientOSS, fileName) => {
//   const url = await clientOSS.signatureUrl(fileName, {
//     'content-disposition': `attachment; filename=${encodeURIComponent(
//       fileName
//     )}`,
//   });
//   // console.log(url);
//   return url;
// };

export const getOssFile = async (url) => {
  const data = await request({
    url: '/tool/oss/getPrivateUrl',
    method: 'post',
    data: { url },
  });
  if (data.success) {
    return data.data;
  }
};
