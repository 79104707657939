<template>
  <van-popup
    v-model="cascaderShow"
    round
    position="bottom"
    :close-on-click-overlay="false"
  >
    <van-cascader
      v-model="cascaderValue"
      title="请选择"
      :options="cascaderList"
      :field-names="fieldNames"
      :closeable="false"
      @close="$parent.cascaderShow = false"
      @finish="onFinish"
    />
  </van-popup>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';

Vue.use(Dialog).use(Toast);

export default {
  name: 'SignList',
  props: ['cascaderShow', 'cascaderList', 'accountId'],
  data() {
    return {
      cascaderValue: '',
      fieldNames: {
        text: 'name',
        value: 'id',
        // children: 'doPendingPositions',
      },
    };
  },
  computed: {
    ...mapState({
      platformId: (state) => state.platformId,
    }),
  },
  created() {},
  methods: {
    onFinish({ selectedOptions }) {
      // console.log(selectedOptions);
      Dialog.confirm({
        title: '请确认签约任务',
        message: `${selectedOptions[0].name}`,
      })
        .then(() => {
          this.getUrl(selectedOptions[0].id);
        })
        .catch(() => {
          // on cancel
        });
    },
    async getUrl(id) {
      Toast.loading({
        message: '请稍候...',
        forbidClick: true,
      });
      const data = await this.$axios.post('/tax/wechatController/doEleSign', {
        accountId: this.accountId,
        taskId: id,
      });
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.$router.replace('result');
        }
      }
    },
  },
};
</script>
